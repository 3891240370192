/**
 * File footer.js
 *
 * Handles position fix for footer when the window is taller than the HTML body
 * Takes the assumed height of the footer when 
 */
jQuery(document).ready( function($){
	var windowHeight, htmlHeight, timeoutCheckHeight, htmlVariant;
	var isGrounded = false;
	timeoutCheckHeight = setTimeout(function(){ checkHeight() }, 5000);

	$(window).resize(function() {
		clearTimeout(timeoutCheckHeight);
		timeoutCheckHeight = setTimeout(function(){ checkHeight() }, 1000);
	});

	function getHeight() {
		windowHeight = $(window).height();
		htmlHeight = $('html').height();
	}

	function checkHeight() {
		getHeight();
		if (isGrounded) {
			htmlVariant = $('.site-footer').outerHeight();
		} else {
			htmlVariant = 0;
		}
		if ( (windowHeight) > (htmlHeight + htmlVariant) ) {
			$('.site-footer').addClass('grounded');
			isGrounded = true;
		} else {
			$('.site-footer').removeClass('grounded');
			isGrounded = false;
		}
	}
});
