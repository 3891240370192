jQuery(document).ready( function($){
    if ( $('.blog').length > 0 ) {
        function loadPosts() {
            var filter = $('#news-filter');
            $.ajax({
                url:filter.attr('action'),
                data:filter.serialize(), // form data
                type:filter.attr('method'), // POST
                success:function(data){
                    $('#news-content').html(data); // insert data
                    $(window).trigger('resize');
                }
            });
            return false;
        }

        loadPosts();

        $('.checkbox input').click(function(){
            if ($(this).attr('name') !== 'twitter_filter') {
                loadPosts();
            } else {
                $(window).trigger('resize');
                $('#tweets').toggle();
            }
        });

        $('.blog-content').on('click', '.pagination a', function(e){
            e.preventDefault();
            var currentPage = $('#news-filter input[name=paged]').val();
            var page = $(this).attr('href');
            if (page !== currentPage) {
                $('#news-filter input[name=paged]').val(page);
                loadPosts();
                $('.pagination .current').removeClass('current').parent().find($(this)).addClass('current');
                $('html, body').animate({scrollTop:0}, '300');
            }
        });

        const configProfile = {
            profile: { screenName: "oddballio" },
            domId: "tweets",
            maxTweets: 3,
            enableLinks: true,
            showUser: false,
            showTime: true,
            showImages: true,
            lang: "en"
        };
        
        if (typeof window !== `undefined`) {
            twitterFetcher.fetch(configProfile);
        }
        
        function buildTweets(placement, tweet, date, image) {
            const twitterContainer = $("<div/>")
                .addClass("twitter-container")
                .insertBefore($(`#${placement}`).parent());

            const twitterHeader = $("<div/>")
                .addClass("twitter-header")
                .appendTo(twitterContainer);

            $("<div/>")
                .addClass("twitter-logo")
                .appendTo(twitterHeader);

            $("<div/>")
                .addClass("twitter-name")
                .text("@oddballio")
                .appendTo(twitterHeader);

            $("<div/>")
                .addClass("twitter-date")
                .html(date)
                .appendTo(twitterHeader);

            $("<div/>")
                .addClass("twitter-msg")
                .html(tweet)
                .appendTo(twitterContainer);

            if (image) {
                $("<div/>")
                .addClass("twitter-img")
                .html(image)
                .appendTo(twitterContainer);
            }

            twitterContainer.wrapInner(
                `<a style='text-decoration:none;' href=${date.href}></a>`
            );
        }

        const interval = setInterval(() => {
        $("#tweets ul li .timePosted a").each(() => {
            const date = $(this).text();
            if (date.startsWith("Posted on ")) {
                $(this).text(date.substr(10));
            }
        });

        const tweet1 = $("#tweets ul li .tweet")[0];
        const tweet2 = $("#tweets ul li .tweet")[1];
        const tweet3 = $("#tweets ul li .tweet")[2];

        const date1 = $("#tweets ul li .timePosted a")[0];
        const date2 = $("#tweets ul li .timePosted a")[1];
        const date3 = $("#tweets ul li .timePosted a")[2];

        const image1 = $("#tweets ul li:eq( 0 )").find("img");
        const image2 = $("#tweets ul li:eq( 1 )").find("img");
        const image3 = $("#tweets ul li:eq( 2 )").find("img");

        if (tweet1 && tweet2 && tweet3) {
            clearInterval(interval);
            if ($(".twitter-container").length !== 3) {
            const stampArray = [];
            $("#news-content")
                .children()
                .not(":first")
                .not(":last")
                .each((index, item) => {
                if (
                    $(item)
                        .children()
                        .attr("id")
                ) {
                    stampArray.push(
                        $(item)
                            .children()
                            .attr("id")
                        );
                    }
                });

            const convertStamp = dateString => {
                if (dateString.startsWith("Posted")) {
                    return false;
                }
                const date = new Date(dateString);
                return date.getTime();
            };

            const insertTweet = (dateString, tweet, date, image) => {
                const tweetStamp = convertStamp(dateString);
                if (tweetStamp) {
                    for (let i = 0; i < stampArray.length; i += 1) {
                        if (i === stampArray.length - 1) {
                            buildTweets(stampArray[i], tweet, date, image);
                            break;
                        } else if (tweetStamp > stampArray[i]) {
                            buildTweets(stampArray[i], tweet, date, image);
                            break;
                        }
                    }
                } else {
                    buildTweets(dateString, tweet, date, image);
                }
            };
            insertTweet('tweets li', tweet1, date1, image1);
            insertTweet('tweets li', tweet2, date2, image2);
            insertTweet('tweets li', tweet3, date3, image3);
            $("#tweet1").attr("href", $(date1).attr("href"));
            $("#tweet2").attr("href", $(date2).attr("href"));
            $("#tweet3").attr("href", $(date3).attr("href"));

            $(tweet1)
                .clone()
                .appendTo($("#tweet1"));

            $(tweet2)
                .clone()
                .addClass("ellipsis")
                .appendTo($("#tweet2"));

            $(tweet3)
                .clone()
                .appendTo($("#tweet3"));
            }
        }}, 200);
    }
});
