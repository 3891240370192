jQuery(document).ready( function($){
    if ( $('#oddball-jobs').length > 0 ) {
        var jobs = [];
        var categories = [];
        var output = '<div class="jobs">';
        $.getJSON('https://boards-api.greenhouse.io/v1/boards/oddball/jobs?content=true', function( data ) {
            $( data.jobs ).each(function(index, value){
                const job = {
                    category: value.departments[0].name,
                    title: value.title,
                    link: value.absolute_url,
                    location: value.location.name,
                }
                jobs[index] = job;
                if (categories.indexOf(value.departments[0].name) === -1) {
                    categories.push(value.departments[0].name)
                }
            });
            $( categories ).each(function(index, catValue){
                output += `<h3>${catValue}</h3>`;
                $( jobs ).each(function(index, jobValue){
                    if ( jobValue.category == catValue ) {
                        output += `<div class="job"><p><a href="${jobValue.link}" target="new_window"><span class="title">${jobValue.title}</span> | <span class="location">${jobValue.location}</span></a></p></div>`;
                    }
                });
            });
            output += '</div>';
            $('#oddball-jobs').append(output)
        }).fail(function( jqxhr, textStatus, error ) {
            var err = textStatus + ", " + error;
            console.log( "Request Failed: " + err );
            $('#oddball-jobs').append('<p>There was an error loading the jobs. Please try again later.</p>');
        });
    }
});
